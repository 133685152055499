import React from "react"
import { graphql, Link } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import { languages } from "../settings"

import { getLangUrlPrefix } from "../util/index"

import Seo from "../parts/seo"
import Layout from "../parts/layout"
import Row from "../parts/row"
import HeadingUnderline from "../parts/headingUnderline"


const NotFound = ({ data, location, pageContext }) => {
  const { t } = useTranslation()

  const pages = data.allSitePage.nodes
  const pathToCheck = location.pathname.slice(3)

  let translations = []
  const translation = pages.find(page => {
    if(!pathToCheck && page.path === "/") return true
    if(pathToCheck === "/" && page.path === "/") return true
    if(pathToCheck !== "/" && page.path.includes(pathToCheck)) return true

    return false
  })

  if(translation) {
    translations = pages.filter(page => page.pageContext.drupalNodeId === translation.pageContext.drupalNodeId)
  }

  return (
    <Layout>
      { translations.length > 0 && <>
        <Seo title={ t("NOT_FOUND-WITH_TRANSLATIONS-TITLE") } />
        <Row classes={["wrapper-m"]}>
          <HeadingUnderline title={ t("NOT_FOUND-WITH_TRANSLATIONS-TITLE") } classes={["center"]} />
          <div className="pane pad-m">
            <p>{ t("NOT_FOUND-WITH_TRANSLATIONS-BODY") }</p>
            <ul className="bullets">
              { translations.map(translation => {
                const language = languages.find(language => language.langcode === translation.pageContext.language)

                return <li key={ translation.path }>
                  <h3><Link className="language-version" to={ translation.path }>
                    <span>{ translation.pageContext.title }</span>
                    { language && <span>({ language.label })</span> }
                  </Link></h3>
                </li>
              }) }
            </ul>
          </div>
        </Row>
      </> }

      { translations.length === 0 && <>
        <Seo title={ t("NOT_FOUND-WITHOUT_TRANSLATIONS-TITLE") } />
        <Row classes={["wrapper-m"]}>
          <HeadingUnderline title={ t("NOT_FOUND-WITHOUT_TRANSLATIONS-TITLE") } classes={["center"]} />
          <div className="pane pad-m">
            <p>{ t("NOT_FOUND-WITHOUT_TRANSLATIONS-BODY") }</p>
            <Link className="button button-small button-go" to={ `/${getLangUrlPrefix(pageContext.language)}` }>{ t("NOT_FOUND-WITHOUT_TRANSLATIONS-HOME") }</Link>
          </div>
        </Row>
      </> }

    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {in: [$language, "pt", "en"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allSitePage {
      nodes {
        path
        pageContext
      }
    }
  }
`

export default NotFound
